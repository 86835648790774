// import Swiper JS
import Swiper from 'swiper';
import { EffectFade, Autoplay } from 'swiper/modules';

// import Swiper styles
import 'swiper/css/bundle';

up.compiler(".swiper", function (element) {
  const swiper = new Swiper(element, {
    autoplay: {
      delay: 2000,
    },
    autoplayDisableOnInteraction: false,
    loop: false,
    observer: true,
    observeParents: true,
    modules: [EffectFade, Autoplay],
    slidesPerView: 1,
    spaceBetween: 0,
    effect: "fade",
    speed: 500,
    allowTouchMove: false,
    noSwiping: true,
    fadeEffect: {
      crossFade: true,
    },
  });
});

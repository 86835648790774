export default (element = {}) => ({
  saved: false,

  init() {
    const index = Alpine.store("application").items.findIndex(
      (el) => {
        if (el.id === element.id) {
          return (this.saved = true);
        }
      }
    );
  },

  toggle() {
    this.saved = !this.saved;

    if (this.saved) {
      Alpine.store("application").items.unshift(element);
    } else {
      const index = Alpine.store("application").items.indexOf(element);
      Alpine.store("application").items.splice(index, 1);
    }
  },

  remove() {
    this.$refs.card.classList.add("opacity-0");
    setTimeout(() => {
      const index = Alpine.store("application").items.indexOf(element);
      Alpine.store("application").items.splice(index, 1);
    }, 300);
  },
});

import 'lazysizes';
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks';

/**
 * Add lazyload support for videos with <source> children
 * Usage:
 *
 * <video class="lazyload">
 *   <source data-src="video.mp4" type="video/mp4">
 * </video>
 */
document.addEventListener('lazybeforeunveil', function (e) {
  if(e.target.tagName !== 'VIDEO') {
    return;
  }

  const children = [...e.target.children].filter(c => c.tagName === 'SOURCE');
  for (var i in children) {
    var videoSource = children[i];
    videoSource.src = videoSource.dataset.src;
  }
});

let APP = window.APP || {};
import { docReady, qsa } from "@js/libs/helpers";
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { SplitText } from "gsap/SplitText";
gsap.registerPlugin(ScrollTrigger, ScrollToPlugin, SplitText);

up.on("up:fragment:inserted", function (event, fragment) {
  console.log("gsap up:fragment:inserted");

  if (window.matchMedia('min-width: 768px').matches) {
    return;
  }

  (function () {
    if (fragment.id !== "downloads" && !fragment.querySelector("#downloads"))
      return;

    let delay = fragment.id === "downloads" ? 1 : 0.5;

    const H1 = fragment.querySelector("#H1");
    gsap.fromTo(
      H1,
      { opacity: 0 },
      {
        delay: delay,
        opacity: 1,
        duration: 1.5,
        ease: "expo",
      }
    );

    const Quote = fragment.querySelector("#Quote");
    gsap.fromTo(
      Quote,
      { opacity: 0 },
      {
        delay: delay,
        opacity: 1,
        duration: 1.5,
        ease: "expo",
      }
    );

    const Text = fragment.querySelector("#Text");
    const TChildren = new SplitText(Text, {
      type: "lines",
      linesClass: "js-lineChild",
    });
    const TParents = new SplitText(Text, {
      type: "lines",
      linesClass: "js-lineParent",
    });

    gsap.fromTo(
      TChildren.lines,
      { x: 0, y: 100 },
      {
        delay: delay + 0.2,
        duration: 1.5,
        x: 0,
        y: 0,
        ease: "expo",
        stagger: 0.3,
        // onComplete: function() {
        //   TParents.revert();
        //   TChildren.revert();
        // }
      }
    );

    const Attribution = fragment.querySelector("#Attribution");
    gsap.fromTo(
      Attribution,
      { opacity: 0, y: 20 },
      {
        delay: delay + 0.5 + 0.3 * TChildren.lines.length,
        opacity: 1,
        duration: 1.5,
        y: 0,
        ease: "expo",
      }
    );
  })();

  // without a timeout, ScrollTrigger triggers every reveal immediately
  if(window.innerWidth >= 1024) {
    setTimeout(() => {
      (function () {
        const elems = fragment.querySelectorAll("[data-reveal]");
        if (!elems) return;
        up.util.each(elems, function (elem) {
          gsap.set(elem, {autoAlpha: 0});
          ScrollTrigger.create({
            trigger: elem,
            once: true,
            onEnter: function (self) {
              var distance = elem.getAttribute("data-reveal-distance") || 100,
                delay = elem.getAttribute("data-reveal-delay") || 0.3,
                x = 0,
                y = distance;

              // 'up' is default; other options are right or left
              switch (elem.getAttribute("data-reveal-direction")) {
                case "right":
                  x = distance * -1;
                  y = 0;
                  break;

                case "left":
                  x = distance;
                  y = 0;
              }

              gsap.fromTo(
                elem,
                {x: x, y: y, autoAlpha: 0},
                {
                  delay: delay,
                  duration: 1.25,
                  x: 0,
                  y: 0,
                  autoAlpha: 1,
                  ease: "expo",
                  overwrite: "auto",
                }
              );
            },
          });
        });
      })();

      (function () {
        const elems = fragment.querySelectorAll("[data-split]");
        if (!elems) return;
        let lastStep = 1;
        up.util.each(elems, function (elem) {
          const SplitScroll = ScrollTrigger.create({
            trigger: elem,
            start: "top top",
            end: "bottom bottom",
            onUpdate: function (self) {
              let step;
              if (self.progress < 0.25) {
                step = 1;
              }
              if (self.progress >= 0.25 && self.progress < 0.5) {
                step = 2;
              }
              if (self.progress >= 0.5 && self.progress < 0.75) {
                step = 3;
              }
              if (self.progress >= 0.75) {
                step = 4;
              }
              if (step != lastStep) {
                let prevEl = document.getElementById("pin" + lastStep);
                let nextEl = document.getElementById("pin" + step);
                gsap.to(prevEl, {
                  opacity: 0,
                  ease: "easeInExpo",
                  duration: 1.25,
                });
                gsap.to(nextEl, {
                  opacity: 1,
                  ease: "easeInExpo",
                  duration: 1.25,
                });
                lastStep = step;
              }
            },
          });
        });
      })();
    }, 0);
  }

  // Line by line
  if(window.innerWidth >= 1024) {
    setTimeout(() => {
      let wrappers = document.querySelectorAll("[data-gsap='text']");
      wrappers.forEach((element, i) => {
        gsap.set(element, {
          opacity: 100,
        });

        let paragraph = element.querySelector("[data-text]");

        let splitParagraph =
          paragraph &&
          new SplitText(paragraph, {
            type: "lines",
            linesClass: "js-lineChild",
          });

        let splitParent =
          paragraph &&
          new SplitText(paragraph, {
            type: "lines",
            linesClass: "js-lineParent",
          });

        function reset() {
          gsap.set(splitParagraph.lines, {
            y: 100,
            opacity: 0,
          });
        }

        reset();

        ScrollTrigger.create({
          trigger: element,
          start: "top 70%",
          end: "bottom 20%",
          // markers: true,
          onEnter: () =>
            gsap.to(splitParagraph.lines, {
              y: 0,
              opacity: 1,
              ease: "expo.out",
              stagger: 0.2,
              duration: 1.5,
            }),
          onLeave: () =>
            gsap.to(splitParagraph.lines, {
              opacity: 0,
            }),
          onEnterBack: () =>
            gsap.to(splitParagraph.lines, {
              y: 0,
              opacity: 1,
            }),
          onLeaveBack: () =>
            gsap.to(splitParagraph.lines, {
              opacity: 0,
              onComplete: reset,
            }),
        });

        // Removed wrappers to let text reflow correctly
        if (splitParagraph.isSplit) {
          window.addEventListener("resize", function () {
            splitParagraph.revert();
          });
        }
      });
    }, 500);
  }

  // Homepage fade in
  if(window.innerWidth >= 1024) {
    gsap.utils.toArray("[data-gsap-trigger]").forEach((section) => {
      const elems = section.querySelectorAll("[data-gsap='fade']");

      gsap.set(elems, {
        y: 50,
        opacity: 0,
        duration: 1,
        ease: "expo",
        overwrite: "auto",
      });

      ScrollTrigger.create({
        trigger: section,
        start: "top 70%",
        end: "bottom 20%",
        // markers: true,
        onEnter: () =>
          gsap.to(elems, {
            y: 0,
            opacity: 1,
            stagger: 0.2,
          }),
        onLeave: () =>
          gsap.to(elems, {
            y: -50,
            opacity: 0,
            stagger: 0.2,
          }),
        onEnterBack: () =>
          gsap.to(elems, {
            y: 0,
            opacity: 1,
            stagger: -0.2,
          }),
        onLeaveBack: () =>
          gsap.to(elems, {
            y: 50,
            opacity: 0,
            stagger: -0.2,
          }),
      });
    });
  }

  // Video Playback
  if(window.innerWidth >= 1024) {
    let videos = gsap.utils.toArray("[data-gsap='video']");
    videos.forEach((video) => {
      let videoEle = video.querySelector("video");

      ScrollTrigger.create({
        trigger: videoEle,
        start: "top 60%",
        // markers: true,
        onEnter: () => videoEle.play(),
      });
    });
  }
});

/**
 * Refresh if our app version has changed
 */
const getVersionFromHtml = function(html) {
  try {
    const meta = html.querySelector('meta[name="X-App-Version"]');
    return meta.getAttribute('content');
  } catch(e) {
    up.log.warn('app:getVersionFromHtml', e.message);
    return '0.0.0:0';
  }
};

const currentVersion = getVersionFromHtml(document);
console.log('app:currentVersion', currentVersion);

// reload if new build version detected
up.on('up:fragment:loaded', (event) => {
  try {
    const parser = new DOMParser();
    const parsedHtml = parser.parseFromString(event.response.text, 'text/html');
    const responseVersion = getVersionFromHtml(parsedHtml);
    up.log.puts('app:responseVersion', responseVersion);
    if(responseVersion !== currentVersion) {
      throw new Error('New version detected: ' + responseVersion + ' !== ' + currentVersion);
    }
  } catch(e) {
    up.log.warn('app:versionMismatch', e.message);
    up.cache.clear();
    event.preventDefault();
    return event.request.loadPage();
  };
});

/**
 * @TODO device detection to limit scope?
 * Safari bug doesn't size srcset when loaded over ajax
 * https://www.debugcn.com/en/article/16843265.html
 * Also has an issue with video!
 */
up.on('up:fragment:inserted', (event, fragment) => {
  const els = fragment.querySelectorAll('img[srcset], video');
  if( ! els) {
    return;
  }
  els.forEach(function(el) {
    el.outerHTML = el.outerHTML;
  });
});

/**
 * Track GA
 */
up.on('up:location:changed', (event) => {
  setTimeout((event) => {
    try {
      let pathname = event.location;
      console.log('Track GA pageview: ' + pathname);
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'Pageview',
        'pagePath': pathname
      });
    } catch(e) {
      console.error('Track pageview error: ' + e.message);
    }
  }, 0, event);
});

up.transition("cross-fade", function (oldElement, newElement, options) {
  // headerTitle can't be transitioned without borking layout
  if (oldElement.id == "headerTitle") {
    return Promise.all([
      up.animate(oldElement, "none", options),
      up.animate(newElement, "none", options),
    ]);
  }

  let fadeOutOptions = {
    ...options,
    duration: 100,
  };

  let fadeInOptions = {
    ...options,
    duration: 100,
  };

  return Promise.all([
    up.animate(oldElement, "fade-out", fadeOutOptions),
    up.animate(newElement, "fade-in", fadeInOptions),
  ]);
});

import Alpine from 'alpinejs';
import persist from '@alpinejs/persist';
import saved from "./saved.js";
import savedItems from "./savedItems.js";

/* plugins */
Alpine.plugin(persist);

/* components */
Alpine.data("saved", saved);
Alpine.data("savedItems", savedItems);

/* saved item functionality */
Alpine.store('application', {
  items : Alpine.$persist([]).as('application_items'),
});

/* start your engines */
window.Alpine = Alpine;
Alpine.start();

export default () => ({
  // Otherwise child events will trigger @dragloave
  dropcheck: 0,
  originalIndexBeingDragged: null,
  indexBeingDragged: null,
  indexBeingDraggedOver: null,
  preDragOrder: [],
  init() {
    this.resetState();
  },
  dragstart(event) {
    console.log('dragstart');
    // Store a copy for when we drag out of range
    this.preDragOrder = [...Alpine.store("application").items];
    // The index is continuously updated to reorder live and also keep a placeholder
    this.indexBeingDragged = event.target.getAttribute('x-ref');
    // The original is needed for then the drag leaves the container
    this.originalIndexBeingDragged = event.target.getAttribute('x-ref');
    // Not entirely sure this is needed but moz recommended it (?)
    event.dataTransfer.dropEffect = "copy";
  },
  updateListOrder(event) {
    console.log('updateListOrder');
    // This fires every time you drag over another list item
    // It reorders the items array but maintains the placeholder
    if (this.indexBeingDragged) {
      this.indexBeingDraggedOver = event.target.getAttribute('x-ref');
      let from = this.indexBeingDragged;
      let to = this.indexBeingDraggedOver;

      if (this.indexBeingDragged == to) return;
      if (from == to) return;

      this.move(from, to);
      this.indexBeingDragged = to;
    }
  },
  resetState() {
    this.dropcheck = 0;
    this.indexBeingDragged = null;
    this.preDragOrder = [...Alpine.store("application").items];
    this.indexBeingDraggedOver = null;
    this.originalIndexBeingDragged = null;
  },
  // This acts as a cancelled event, when the item is dropped outside the container
  revertState() {
    Alpine.store("application").items = this.preDragOrder.length ? this.preDragOrder : Alpine.store("application").items;
    this.resetState();
  },
  // Just repositions the placeholder when we move out of range of the container
  rePositionPlaceholder() {
    Alpine.store("application").items = [...this.preDragOrder];
    this.indexBeingDragged = this.originalIndexBeingDragged;
  },
  move(from, to) {
    let items = Alpine.store("application").items;
    if (to >= items.length) {
      let k = to - items.length + 1;
      while (k--) {
        items.push(undefined);
      }
    }
    items.splice(to, 0, items.splice(from, 1)[0]);
    Alpine.store("application").items = items;
  },
  remove(index) {
    Alpine.store("application").items.splice(index, 1);
  }
});
